import React from 'react';
import './Contact.css';
import { FaLinkedin } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { FaMedium } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";

function Contact() {
    return (
        <div id='contact' className="contact">
            <div>
                <h1>Lets Connect!</h1>
                <div className='container'>
                    <div className='box'>
                        <a href="mailto:eliashabash18@gmail.com" style={{ color: "whitesmoke" }}>
                            <IoMailSharp size={30} /> Email
                        </a>
                    </div>
                    <div className='box'>
                        <FaPhone size={30} /> 408-658-4036
                    </div>
                    <div className='box'>
                        <a href="https://medium.com/@eliashabash23" style={{ color: "whitesmoke" }}>
                            <FaMedium size={30} /> Medium
                        </a>
                    </div>
                    <div className='box'>
                        <a href="https://www.linkedin.com/in/elias-habash/" style={{ color: "whitesmoke" }}>
                            <FaLinkedin size={30} /> LinkedIn
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Contact;