import React from 'react';
import './About.css';

const user = {
  name: "It's Eli",
  imageUrl: require('../../assets/headshot.jpeg'),
  imageSize: 200,
};

function About() {
  return (
    <div id='about' className="about container">
      <div className='box'>
        <h1>{user.name}</h1>
        <img
          className="avatar"
          src={user.imageUrl}
          alt={'Photo of ' + user.name}
          style={{
            width: user.imageSize,
            height: user.imageSize,
            borderRadius: 100
          }}
        />
      </div>
      <div className='box'>
        <h1>Welcome!</h1>
        <p className='font'>I am an enthusiastic and dedicated Field Engineer/Cloud Architect with a strong 
          foundation in cloud computing principles and a passion for designing 
          innovative solutions. I have gained hands-on experience with leading 
          cloud platforms such as AWS, Azure, and Google Cloud through my years
          working in the tech industry.</p>
      </div>
    </div>
  );
}

export default About;