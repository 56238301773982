import React from 'react';
import './Certificates.css';

function Certificates() {
  return (
    <div className="certificates">
      <h1 id='certificates' style={{ color: "whitesmoke", textAlign: "center" }}>Certifications</h1>
      <div className="container">
        <div className="box">
          <a href='https://www.credly.com/badges/083d7b32-9ad1-492f-bca1-dfe1362c1166' target="_blank" rel="noreferrer">
            <img src={require("../../assets/AWS-Cloud-Practioner.png")} alt="AWS Cloud Practioner" style={{ width: 200, height: 200 }} />
          </a>
        </div>
        <div className="box">
          <a href='https://www.credly.com/badges/7e799bfd-3a37-42e5-b9e3-57dbd4e95c84' target='_blank' rel="noreferrer">
            <img src={require("../../assets/aws-solutions-architect-associate.png")} alt="AWS Solutions Architect Associate" style={{ width: 200, height: 200 }} />
          </a>
        </div>
        <div className="box">
          <a href='https://www.credly.com/badges/fd568a1e-79ce-4f58-bb95-82064ef140ff' target='_blank' rel="noreferrer">
            <img src={require("../../assets/aws-developer-associate.png")} alt="AWS Developer Associate" style={{ width: 200, height: 200 }} />
          </a>
        </div>
        <div className="box">
          <a href='https://www.credly.com/badges/d4462e67-e35d-460f-8c29-e8d69bc02043' target='_blank' rel='noreferrer'>
            <img src={require("../../assets/certified-k8s-administrator.png")} alt="Certified Kubernetes Administrator" style={{ width: 200, height: 200 }} />
          </a>
        </div>
        <div className="box">
          <a href='https://learn.microsoft.com/api/credentials/share/en-us/EliasHabash-7146/237007751A84D4AA?sharingId=EB78E15C664E7EB4' target='_blank' rel='noreferrer'>
            <img src={require("../../assets/azure-administrator-associate.png")} alt="Azure Administrator Associate" style={{ width: 200, height: 200 }} />
          </a>
        </div>
        <div className="box">
          <a href='https://credentials.databricks.com/9e4c3590-e8d7-4c6b-ad15-99704d7d7ff3#gs.esoo6c' target='_blank' rel='noreferrer'>
            <img src={require("../../assets/databricks data engineer associate.png")} alt="Databricks Data Engineer Associate" style={{ width: 150, height: 200 }} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Certificates;