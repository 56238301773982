import Navbar from './Components/Navbar/Navbar';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import './App.css';
import Certificates from './Components/Certificates/Certificates';

function App() {
  return (
    <div className="App font">
      <Navbar />
      <About />
      <Certificates />
      <Contact />
    </div>
  );
}

export default App;
